//import your stylesheets below
// @import url('https://fonts.googleapis.com/css?family=Lato')
// @import url('https://fonts.googleapis.com/css?family=Lato:100,300,300i,400,400i,700,700i,900,900i')
// @import url('https://fonts.googleapis.com/css?family=Lato:100,300,300i,400,400i,700,900')
@import "_lato-modern-browsers";
@import "_custom_bs_variables";
@import "_media-queries";
@import "_rem";
@import "_mixins";
@import "bootstrap";
@import "font-awesome";

//import your stylesheets below

//Farben
$broken-white: #F4F4F4;
$base-blue: #004E7C;
$base-blue-dk: #0B2D42;


@import "_common";
@import "_banner";
@import "_cover_new";
// entfernen:
@import "_banner_v1";
@import "_landing-head";
@import "_symbole";
@import "_accordion";
@import "_sidebar";
@import "_gridBoxes";
@import "_landing";
@import "_gradients";
@import "_test";
// @import "nurtest/_tooltip"
// @import "nurtest/_nav"
// @import "nurtest/_nav2"

.gradient1 {
  background: -moz-linear-gradient(78.57% 250% 135deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
  background: -webkit-linear-gradient(135deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
  background: -webkit-gradient(linear, 78.57% 250%, 23.58% -134.97%, color-stop(0, rgba(78, 67, 118, 1)), color-stop(1, rgba(20, 85, 129, 1)));
  background: -o-linear-gradient(135deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
  background: -ms-linear-gradient(135deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#4E4376', endColorstr='#145581' ,GradientType=0)";
  background: linear-gradient(-45deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
  position: absolute;
  left: 751px;
  top: 182px;
  width: 525px;
  height: 75px;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4E4376',endColorstr='#145581' , GradientType=1); }

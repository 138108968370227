
// ************ Using media queries ************

$screen-max-phone: "only screen and (max-width : 575px)";
$screen-min-xsp: "only screen and (min-width: 375px)"; // sm and up
$screen-min-s: "only screen and (min-width: 576px)"; // sm and up
$screen-min-sm: $screen-min-s;
$screen-min-md: "only screen and (min-width : 768px)";
$screen-min-lg: "only screen and (min-width : 992px)";
$screen-min-xl: "only screen and (min-width : 1200px)";
$screen-min-xxl: "only screen and (min-width : 1600px)";
$screen-min-xxxl: "only screen and (min-width : 1800px)";

// min-height
$screen-min-h1200: "only screen and (min-height: 1200px)"; //iphone x
$screen-max-1400: "only screen and (max-width : 1400px)";
// Beispiel
// @media #{$screen-min-s} {
//   background: red;
// }

// @media #{$screen-max-phone}
// @media #{$screen-min-xsp}
// @media #{$screen-min-s}
// @media #{$screen-min-md}
// @media #{$screen-min-lg}
// @media #{$screen-min-xl}
// @media #{$screen-min-xxl}
// @media #{$screen-min-xxxl}


// ************ ASPECT RATIO ************

// 4:3 (1,33333) PAL, VGA, iPad (Retina)
// 16:9 (1,7777) HD, Eizo, iPhone 6/7+, Apple iMac Retina 4K, 5K
// 71:40 (1,775) iPhone 5
// 667:375 (1,7786) iPhone 6,7
// 16:10, 8:5 (1,6)
// 3:2 (1,5) iPhone 4
// 5:3 (1,66666)
// 5:4 (1,25)
// 5:8 (0,625) Amazon Kindle Fire HDX 8.9


$screen-4-3-min: "screen and ( min-aspect-ratio: 4/3 )";
$screen-16-9-min: "screen and ( min-aspect-ratio: 640/360 )"; // 16:9 galaxy note
$screen-16-10-min: "screen and ( min-aspect-ratio: 16/10 )"; // 16:9 galaxy note
$screen-3-2-min: "screen and ( min-aspect-ratio: 3/2 )"; //
$screen-5-3-min: "screen and ( min-aspect-ratio: 5/3 )"; //
$screen-5-4-min: "screen and ( min-aspect-ratio: 5/4 )"; //
$screen-5-8-min: "screen and ( min-aspect-ratio: 5/8 )"; //  5:8
$screen-iphone6-min: "screen and ( min-aspect-ratio: 667/375 )"; //
$screen-iphone8-min: "screen and ( min-aspect-ratio: 1334/750 )"; //
$screen-iphoneX-min: "screen and ( min-aspect-ratio: 2436/1125 )"; //

// Portrait
$screen-4-3-p-min: "screen and ( min-aspect-ratio: 3/4 )";
$screen-16-9-p-min: "screen and ( min-aspect-ratio: 9/16 )"; // 16:9 galaxy note
$screen-16-10-p-min: "screen and ( min-aspect-ratio: 10/16 )"; // 16:9 galaxy note
$screen-3-2-p-min: "screen and ( min-aspect-ratio: 2/3 )"; //
$screen-5-3-p-min: "screen and ( min-aspect-ratio: 3/5 )"; //
$screen-5-4-p-min: "screen and ( min-aspect-ratio: 4/5 )"; //
$screen-5-8-p-min: "screen and ( min-aspect-ratio: 8/5 )"; //  5:8
$screen-iphone-p-min: "screen and ( min-aspect-ratio: 0.546 )"; //  iPhone SE
$screen-iphoneX-p-min: "screen and ( min-aspect-ratio: 1125/2436 )"; //portrait

$screen-4-3-max: "screen and ( max-aspect-ratio: 4/3 )";
$screen-16-9-max: "screen and ( max-aspect-ratio: 640/360 )"; // 16:9 galaxy note
$screen-16-10-max: "screen and ( max-aspect-ratio: 16/10 )"; // 16:10
$screen-3-2-max: "screen and ( max-aspect-ratio: 3/2 )"; //  3:2
$screen-5-3-max: "screen and ( max-aspect-ratio: 5/3 )"; //  5:3
$screen-5-4-max: "screen and ( max-aspect-ratio: 5/4 )"; //  5:4
$screen-5-8-max: "screen and ( max-aspect-ratio: 5/8 )"; //  5:8
$screen-iphone6-max: "screen and ( max-aspect-ratio: 667/375 )";


// .grafHeader
//   position: relative
//   top: 0
//   bottom: 0
//   left: 0
//   right: 0
//   display: block
//   align-items: center
//   justify-content: center
//   margin-top: 18vh
//   @media #{$screen-min-s}
//     @media #{$screen-16-9-min}
//       margin-top: 40vh
//     @media #{$screen-5-3-min}
//       margin-top: 40vh
//   @media #{$screen-min-md}


// ************ Mixin Retina definieren ************

@mixin retina {
  @media
  screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min-resolution: 192dpi),
  screen and (min-resolution: 2dppx) {
    @content;
  }
}
// Mixin anwenden
// div {
//   width:200px;
//   height:100px;
//   background-image:url(img/bild.png);
//
//   @include retina {
//     background-image:url(img/bild@2x.png);
//     background-size: 200px 100px;
//   }
// }



// ************ Function that converts pixels to vw ************

@function get-vw($target) {
  $vw-context: (1000*0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
@function get-xs-vw($target) {
  $vw-context: (320*0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
@function get-xsp-vw($target) {
  $vw-context: (375*0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
@function get-sm-vw($target) {
  $vw-context: (576*0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
@function get-md-vw($target) {
  $vw-context: (768*0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
@function get-lg-vw($target) {
  $vw-context: (992*0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
@function get-xl-vw($target) {
  $vw-context: (1200*0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
@function get-xxl-vw($target) {
  $vw-context: (1600*0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
@function get-xxxl-vw($target) {
  $vw-context: (1800*0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

// @media #{$screen-min-xsp}
// get-xsp-vw($target)
// @media #{$screen-min-s}
// get-sm-vw($target)
// @media #{$screen-min-md}
// get-md-vw($target)
// @media #{$screen-min-lg}
// get-lg-vw($target)
// @media #{$screen-min-xl}
// get-xl-vw($target)
// @media #{$screen-min-xxl}
// get-xxl-vw($target)
// @media #{$screen-min-xxxl}
// get-xxxl-vw($target)

// Benutzung
// .headline {
//   font-size: get-vw(72px);
// }

.overlayxy {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

// @import url('https://fonts.googleapis.com/css?family=Lato:100,300,300i,400,400i,700,700i,900,900i')

//Farben
$broken-white: #F4F4F4;
$base-blue: #004E7C;
$base-blue-dk: #0B2D42;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
// $font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-sans-serif:  'Lato', sans-serif;
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// $font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

// $font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
// $font-size-lg:                ($font-size-base * 1.25) !default;
// $font-size-sm:                ($font-size-base * .875) !default;
//
// $font-weight-light:           300 !default;
// $font-weight-normal:          400 !default;
// $font-weight-bold:            700 !default;
//
// $font-weight-base:            $font-weight-normal !default;
// $line-height-base:            1.5 !default;
//
// $h1-font-size:                $font-size-base * 2.5 !default;
// $h2-font-size:                $font-size-base * 2 !default;
// $h3-font-size:                $font-size-base * 1.75 !default;
// $h4-font-size:                $font-size-base * 1.5 !default;
// $h5-font-size:                $font-size-base * 1.25 !default;
// $h6-font-size:                $font-size-base !default;

// $beratung-h2-font-size-m:  25px;

// Body
//
// Settings for the `<body>` element.

// $body-bg:                   $white !default;
$body-bg:                   $broken-white !default;
// $body-color:                $gray-900 !default;
$text-color:    #575A5D;


//== Tooltips
//
//##

// ** Tooltip max width
$tooltip-max-width:           200px !default;
// ** Tooltip text color
$tooltip-color:               #fff !default;
// ** Tooltip background color
$tooltip-bg:                  $base-blue-dk !default;
$tooltip-opacity:             .9 !default;


// Lato
// light 300
// regular 400
// bold  700
// black 900

// BERATUNG
$beratung-h2-color: #001A33;
$beratung-h2-font-size-xs:  20px;
$beratung-h2-line-height-xs:  28px;
$beratung-h2-font-weight: 700;
$beratung-h2-font-size-xl:  35px;


$beratung-text-font-size-xs:  16px;
$beratung-text-line-height-xs: 24px;
$beratung-text-font-size-md:  20px;
$beratung-text-line-height-md: 28px;
$beratung-text-font-size-xxl:  22px;
$beratung-text-line-height-xxl: 32px;


html#tmpcover,
#tmpcover body {
  height: 100%; }

#tmpcover {
  body {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #fff;
    // text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5)
    // box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5)
    // background-color: $base-blue-dk
    background: linear-gradient(0deg, #20183e 0%, #145581 60.1%); } }

#tmpcover {
  a, a:visited {}
  color: #88a5ca;
  a:focus,
  a:hover {
    color: #88a5ca;
    letter-spacing: 0.7px;
    text-decoration: none; }
  .btn-secondary,
  .btn-secondary:hover,
  .btn-secondary:focus {
    color: #333;
    text-shadow: none /* Prevent inheritance from `body` */;
    background-color: #fff;
    border: .05rem solid #fff; }
  .cover-container {
    max-width: 42em; }
  // * Header
  .masthead {
    margin-bottom: 2rem; }
  .masthead-brand {
    margin-bottom: 0;
    @media (min-width: 48em) {
      float: left; } }
  .nav-masthead {
    @media (min-width: 48em) {
      float: right; }
    .nav-link {
      padding: .25rem 0;
      font-weight: 700;
      color: rgba(255, 255, 255, .5);
      background-color: transparent;
      border-bottom: .25rem solid transparent; }
    &.nav-link:hover,
    &.nav-link:focus {
      border-bottom-color: rgba(255, 255, 255, .25); }
    .nav-link + .nav-link {
      margin-left: 1rem; }
    .active {
      color: #fff;
      border-bottom-color: #fff; } }
  .cover {
    padding: 0 1.5rem;
    .btn-lg {
      padding: .75rem 1.25rem;
      font-weight: 700; } }
  // &.ani
  //   width: 480px
  //   height: 370.6px
  .hk-cover {
    background-image: url('../img/hk-mbH-logo-full.svg');
    // background-image: url('../img/icons/logo-anim-front.svg')
    background-repeat: no-repeat;
    // background-position: center;
    // background-size: auto 10rem;
    background-size: cover;
    width: 17rem;
    height: 5.888rem;
    @media (min-width: 576px) {
      width: 30rem;
      height: 10.391rem; } }

  .hk-anim-cover {
    // background-image: url('../img/hk-mbH-logo-full.svg')
    // background-image: url('../img/icons/logo-anim-front.svg')
    background-repeat: no-repeat;
    // background-position: center;
    // background-size: auto 10rem;
    background-size: cover;
    width: 17rem;
    height: 5.888rem;
    @media (min-width: 576px) {
      width: 30rem;
      height: 10.391rem; } }

  #cover-svg {
    width: 480px;
    height: 370.6px; }
  #cover-top-dist {
    height: 20vh;
    @media (min-width: 576px) {
      height: 30vh; } }
  .mastfoot {
    background-color: transparent;
    p {
      font-size: 20px;
      font-variant-caps: all-petite-caps;
      color: #a5a5a5;
      line-height: 22px; }
    a {
      color: #88a5ca; } }
  .tmplinkleiste {
    a {
      color: #88a5ca; } } }

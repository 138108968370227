/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local("Lato Hairline"), local("Lato-Hairline"), url('../fonts/lato-v14-latin-100.woff2') format("woff2"), url('../fonts/lato-v14-latin-100.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"), url('../fonts/lato-v14-latin-100italic.woff2') format("woff2"), url('../fonts/lato-v14-latin-100italic.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url('../fonts/lato-v14-latin-300.woff2') format("woff2"), url('../fonts/lato-v14-latin-300.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"), url('../fonts/lato-v14-latin-300italic.woff2') format("woff2"), url('../fonts/lato-v14-latin-300italic.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url('../fonts/lato-v14-latin-regular.woff2') format("woff2"), url('../fonts/lato-v14-latin-regular.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local("Lato Italic"), local("Lato-Italic"), url('../fonts/lato-v14-latin-italic.woff2') format("woff2"), url('../fonts/lato-v14-latin-italic.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url('../fonts/lato-v14-latin-700.woff2') format("woff2"), url('../fonts/lato-v14-latin-700.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url('../fonts/lato-v14-latin-700italic.woff2') format("woff2"), url('../fonts/lato-v14-latin-700italic.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"), url('../fonts/lato-v14-latin-900.woff2') format("woff2"), url('../fonts/lato-v14-latin-900.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: local("Lato Black Italic"), local("Lato-BlackItalic"), url('../fonts/lato-v14-latin-900italic.woff2') format("woff2"), url('../fonts/lato-v14-latin-900italic.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
//ersetzt durch grafik h1.aktuelles
.pagetitle {
  background-image: url("img/h1_bg.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 587px;
  height: 178px;
  color: #fff;
  position: relative;
  top: 125px;
  h1 {
    font-size: 4.5em;
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0; }
  span {
    font-size: 1.2em;
    font-weight: 100 !important; } }

//#banner .imgHeader .datenschutz

.imgHeader {
    background-image: url("img/header-aktuelles.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    // height: 34vh
    height: 45vh;
    &.datenschutz {
      // background-image: url("img/dsberatung.png")
      background-image: url("img/bannerZ2.jpg"); }
    &.steuerberatung {
      // background-image: url("img/dsberatung.png")
      background-image: url("img/banner-steuer.jpg"); }
    &.unternehmensberatung {
      // background-image: url("img/dsberatung.png")
      background-image: url("img/banner-unternehmen.jpg"); }
    &.test1 {
      background-image: url("img/bannerZ2.jpg"); }
    &.test2 {
      background-image: url("img/bannerZ3.jpg"); } }


// #banner

// hero-datenschutz_m.jpg
// hero-datenschutz_t.jpg
// hero-datenschutz_d.jpg
// hero-datenschutz_xl.jpg


// .bannerimgHeader::before,
// .bannerimgHeader::after
//   clear: both
//   content: ''
//   display: block


#herobanner .hero-image {
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: 620px;
  margin-bottom: 80px;
  picture, img {
    height: auto;
    width: 100%;
    position: relative;
    margin: 0 auto; } }

.bannertitel {
  position: relative;
  top: 7.5rem;
  display: inline-block;
  @media #{$screen-min-s} {
    top: 9rem; }
  @media #{$screen-min-md} {
    top: 11rem; }
  // @media #{$screen-min-lg}
  // @media #{$screen-min-xl}
  h1 {
    // padding-right: 0
    // padding-left: 0
    // float: none
    position: relative;
    left: -1rem;
    // @media #{$screen-min-s}
    // @media #{$screen-min-md}
    // @media #{$screen-min-lg}
 } }    // @media #{$screen-min-xl}

.bannertitelbox {
  position: relative;
  // top: 7.5rem
  display: inline-block;
  @media #{$screen-min-s} {
 }    // top: 9rem
  @media #{$screen-min-md} {
 }    // top: 11rem
  // @media #{$screen-min-lg}
  // @media #{$screen-min-xl}
  h1 {
    // padding-right: 0
    // padding-left: 0
    // float: none
    // position: relative
    // left: -1rem
    // @media #{$screen-min-s}
    // @media #{$screen-min-md}
    // @media #{$screen-min-lg}
 } }    // @media #{$screen-min-xl}

.bereichIcon {
    width: 59.5px;
    height: 78.5px;
    object-fit: contain;
    mix-blend-mode: undefined; }

h1.aktuelles {
  background-image: url("img/aktuelles-h1.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 587px;
  height: 178px; }

h1.datenschutzberatung {
  background-image: url("img/dsberatung-h1.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  // width: 756px
  // height: 76px
  // margin-left: 0
  // width: 250px
  // height: 25px
  // width: 15.63rem
  // height: 1.563rem
  // width: 78.125vw
  width: auto;
  height: 7.8125vw;
  // height: auto
  display: flex;
  @media #{$screen-min-s} {
    // height: 40px
 }    // margin: 0 auto 15px auto
  @media #{$screen-min-md} {
 }    // height: 52px
  @media #{$screen-min-lg} {
    // width: 68.143vw
    width: auto;
    height: 6.814vw; }
  @media #{$screen-min-xl} {
    // width: 47.62vw
    // height: 4.762vw
    width: auto;
    height: 4.8vw; } }

h1.standard {
  // font-size: 4.5em
  font-size: 3.5em;
  font-weight: 900;
  line-height: 1em;
  color: $broken-white; }

h1.steuerberatung {
  background-image: url("img/steuerberatung-h1.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 565px;
  height: 84px;
  @media #{$screen-min-xl} {} }

h1.unternehmensberatung {
  background-image: url("img/unternberatung-h1.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 856px;
  height: 84px; }

.titelContainer {
  display: block;
  width: 100%;
  margin-left: 10vw; }

.titel,
#svgContainer {
  display: inline-block; }

.titel {
  margin: 0.5em 0 0 1em; }

// herobanner

#herobanner {
  canvas {
    display: block;
    width: 100%;
    visibility: hidden; } }

.svginside {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  svg, figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; } }

#cnvsx {
  width: 100vw;
  height: 30vh; }

#pzwei {
  z-index: 100;
  position: fixed !important;
  top: 0;
  left: 0;
  display: block; }

#pzweix {
  display: none; }

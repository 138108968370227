
//Maße VARIABLEN
// $gridBoxWidth: col-(xl)-3 (132px)
$pageBgColor: #F4F4F4;
$gridBoxHeight: 172px;
$gridBoxTitelHeight: 88px;
$gridBoxTextHeight: 84px;
$gridBoxTitelBgColor: #004E7C;
// rgba(0, 78, 124, 1)
$gridBoxTextBgColor: #FFF;

$gridBoxVertAbstand: 4.5rem;

$newsBoxHeight: 210px;
//$newsBoxTitleWidth: col-(xl)-3 (132px)
//$newsBoxTextWidth: col-(xl)-6 (?px)
$newsBoxTextHeight: $newsBoxHeight;

$boxpadding: 1.3rem;
$newsboxpadding: 1.5rem;

// Font Sizes
// p: 1em = 16px
$gridBoxTextSize: 1em;
$gridBoxlineHeight: 1.3em;
//(? 25.6px:)
$newsBoxlineHeight: 1.6;
// Kleiner text
$textSizeSmall: 0.86em;
// h1
// h2 1.5em = 24px
$h2FontSize: 1.5em;
$blueboxH2: $h2FontSize;
$gridBoxH2Color: #EFEFEF;
// h3 1.25em = 20px, alt: 1.1em
$h3FontSize: 1.2em;
$newsBoxTitelSize: 1.3rem;
$whiteBoxH3: $h3FontSize;
// h4
$newsBoxTitelTextSize: $h3FontSize;

$bluebox-md-left-padding: 1.8rem;

.pageBg {
    background-color: $pageBgColor;
    height: 100vh; }

.gridBox {
    height: $gridBoxHeight;
    margin-bottom: $gridBoxVertAbstand;
    .titel {
        background-color: $gridBoxTitelBgColor;
        height: $gridBoxTitelHeight; }
    .text {
        background-color: $gridBoxTextBgColor;
        height: $gridBoxTextHeight;
        p {}
        font-size: $gridBoxTextSize; } }
.newsRow {
    margin-bottom: 3rem; }
.newsBox {
    height: $newsBoxHeight;
    .titel {
        background-color: $gridBoxTitelBgColor;
        height: $newsBoxHeight;
        align-content: center;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30% auto;
        &.privacy {
            background-image: url("img/eprivacy.png"); }
        &.finanzen {
            background-image: url("img/finanzen.png"); }
        h3 {
            font-size: $newsBoxTitelSize; }
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            padding-top: 30%; } }
    .text {
        background-color: $gridBoxTextBgColor;
        height: $newsBoxHeight;
        // padding: 2%
        padding: $newsboxpadding;
        h4 {
            font-size: $newsBoxTitelTextSize;
            font-weight: 900;
            margin-bottom: 2rem; }
        p {
            font-size: $gridBoxTextSize;
            font-weight: 300;
            line-height: $newsBoxlineHeight;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; }
        .newsInfo {
            color: #b7b2b2;
            font-size: $textSizeSmall;
            display: block;
            float: right;
            width: 100%;
            position: absolute;
            bottom: 0;
            padding-bottom: 2%; } } }




.bluebox {
  background-color: $base-blue;
  height: 15rem;
  // flex: 33%
  // max-width: 33%
  background-repeat: no-repeat;
  background-position: 0 0;
  overflow: hidden;
  @media #{$screen-min-md} {
    height: 16rem; }
  // @media #{$screen-min-lg}
  //// height: 15rem
  // @media #{$screen-min-lg}
  //   height: 16rem
  // @media #{$screen-min-xl}
  //   height: 18rem
  &.img_a {
 }    // background-image: url("img/element3@2x.png")
  &.datenschutz {
 }    // background-image: url("../img/icons/datenschutz-ani.svg")
  h2 {
    // font-size: 1.5rem
    font-size: 1.45rem;
    font-weight: 700;
    // padding: $boxpadding
    padding: 1.3rem;
    color: $gridBoxH2Color;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    @media #{$screen-min-s} {
      // font-size: get-sm-vw(28px)
      font-size: 1.8rem; }
    @media #{$screen-min-md} {
      padding-left: $bluebox-md-left-padding;
 }      // font-size: 2.3rem
    @media #{$screen-min-lg} {
      font-size: 1.4rem;
 }      // font-size: 1.42rem
    // @media #{$screen-min-xl}
    //// font-size: get-xl-vw(26px)
 } }    //// font-size: 1.8rem

.whitebox {
  background-color: $gridBoxTextBgColor;
  color: #0B0B0B;
  @media #{$screen-min-md} {
    min-height: 19rem; }
  // @media #{$screen-min-lg}
  //   min-height: 23rem
  // @media #{$screen-min-xl}
  //   min-height: 24rem
  p {
    font-size: $gridBoxTextSize;
    padding: 0.6rem 1rem 2rem 1.3rem;
    line-height: $gridBoxlineHeight;
    @media #{$screen-min-md} {
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding: 0.8rem 1rem 2rem $bluebox-md-left-padding; }
    @media #{$screen-min-lg} {
      font-size: 1.05rem;
      line-height: 1.5rem; }
    // -ms-hyphens: auto
    // -webkit-hyphens: auto
 }    // hyphens: auto
  h3 {
    font-size: $whiteBoxH3;
    padding: 1.5rem 1.3rem 0 1.3rem;
    font-weight: 700;
    @media #{$screen-min-md} {
      font-size: 1.3rem;
      padding: 2.2rem 1.3rem 0 $bluebox-md-left-padding; }
    @media #{$screen-min-lg} {
      font-size: 1.2rem; }
    // @media #{$screen-min-md}
    //   font-size: 1.6rem
    // @media #{$screen-min-md}
 } }    //   font-size: 1.6rem

// Abstand der Navboxen
.mb-45r {
  margin-bottom: 4.5rem !important; }

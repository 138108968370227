$pageBgColor: #F4F4F4;

html {
  width: 100%;
  height: 100%; }

body {
  width: 100%;
  height: 100%;
  font-family: 'Lato', sans-serif;
  color: $text-color;
  webkit-tap-highlight-color: #222;
  top: {}

  &.modal-open {
    padding-right: 0 !important; } }

/* workaround modal-open padding issue */

body.hellgrau {
  background-color: $pageBgColor; }

.mainView, .contentWidth {
  // width: 100%
  @media screen and (min-width: 992px) {
    // nur mit opaker sidebarRight
 } }    // width: 96%

hr {
  max-width: 120px;
  border-width: 3px;
  opacity: 0.08;
  margin-top: 25px;
  margin-bottom: 25px;
  &.light {
    border-color: #fff;
    opacity: 1; }
  &.primary {
    border-color: #007FFF;
    opacity: 1; } }

a {
  color: #007FFF;
  -webkit-transition: all .35s;
  -moz-transition: all .35s;
  transition: all .35s;
  &:hover, &:focus {
    color: #222;
    outline: 0; } }

p {
  font-size: 17px;
  line-height: 1.7;
  margin-bottom: 20px; }

.text-normal {
  font-family: 'Helvetica Neue',Arial,sans-serif; }

.wide-space {
  letter-spacing: 1.6px; }

.icon-lg {
  font-size: 50px;
  line-height: 18px; }

.bg-primary {
  background-color: #007FFF; }

.bg-alt {
  background-color: #fff; }

.text-faded {
  color: rgba(245, 245, 245, 0.7); }

.text-dark {
  color: #001A33; }

.text-light {
  color: #fff;
  &:hover, &:focus {
    color: #eee;
    text-decoration: none; } }

.text-primary {
  color: #007FFF; }

section {
  padding: 70px 0; }

aside {
  padding: 45px 0; }

footer {
  padding: 45px;
  padding-top: 40px;
  background-color: #e0e0e3;
  h4, h6 {
    color: #fff; } }

.navbar-default {
  border-color: rgba(35, 35, 35, 0.05);
  background-color: #fff;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s; }

.navbar-brand {
  color: #007FFF;
  padding: 1px;
  line-height: 1.90em;
  -webkit-transition: padding .2s ease-in;
  -moz-transition: padding .2s ease-in;
  transition: padding .2s ease-in;
  float: none;
  &:hover, &:focus {
    color: #007FFF; } }

.navbar-default {
  .navbar-toggle {
    &:focus, &:hover {
      background-color: #001A33; }
    border-color: transparent; }
  .navbar-collapse {
    border-color: transparent; }
  .nav {
    > li > a {
      font-size: 12px;
      color: #001A33;
      text-transform: uppercase;
      &:focus {
        font-size: 12px;
        color: #001A33;
        text-transform: uppercase; }
      &:hover, &:focus:hover {
        color: #007FFF; } }
    .nav-link.active {
      color: #007FFF !important;
      background-color: transparent;
      &:focus {
        color: #007FFF !important;
        background-color: transparent; } } } }

/* responsive nav */
@media (max-width: 48em) {
  .navbar-default .navbar-nav {
    > .nav-item {
      float: none;
      margin-left: .1rem; }
    float: none !important; } }

@media (min-width: 48em) {
  .navbar-default {
    border-color: transparent;
    background-color: #fff;
    min-height: 60px; }
  .navbar-brand {
    color: #007FFF;
    float: left;
    padding: 9px;
    padding-left: 1px; }
  .navbar-default {
    .navbar-brand {
      &:hover, &:focus {
        color: #001A33; } }
    .nav > li > a {
      color: #001A33;
      padding-left: 5px;
      padding-top: 21px;
      letter-spacing: 1.1px;
      &:focus {
        color: #001A33;
        padding-left: 5px;
        padding-top: 21px;
        letter-spacing: 1.1px; }
      &:hover, &:focus:hover {
        color: #001A33; } } }
  #topNav {
    background-color: #fff; } }

.no-padding {
  padding-left: 0;
  padding-right: 0; }

.fullview {
  height: 100vh; }

// Links für Tooltips
a.ttlink {
  &:hover {
    text-decoration-style: dotted; } }

// .card-deck
//   @include media-breakpoint-only(lg)
//     column-count:

//   @include media-breakpoint-only(xl)
//     column-count: 5
.m-center {
  margin-left: auto; }

// Generic Styles

.gtpl {
  .seitentitel {
    position: relative;
    // top: rem(-190px)
    top: rem(-105px);
    margin-bottom: rem(-60px);
    @media #{$screen-min-md} {
      top: rem(-165px); }
    @media #{$screen-min-lg} {
      top: rem(-160px); }
    @media #{$screen-min-xl} {
      top: rem(-190px); }
    @media #{$screen-min-xxl} {
      top: rem(-210px); }
    h1 {
      color: $broken-white;
      font-size: rem(28px);
      font-weight: 700;
      @media #{$screen-min-s} {
        font-size: rem(44px); }
      @media #{$screen-min-md} {
        font-size: rem(48px); }
      @media #{$screen-min-lg} {
        font-size: rem(54px); }
      @media #{$screen-min-xxxl} {
        font-size: rem(64px); } } }

  a {
    color: #195cb3; }
  &:hover {
    font-weight: 500; }

  p {
    word-wrap: break-word;
    a {
      color: #195cb3; } }

  p, ol li, ul li {
    font-size: rem(18.8px);
    font-weight: 300;
    line-height: 1.7rem;
    word-wrap: break-word; }
  p, ol, ul {
    margin-bottom: 20px; }
  ul {
    list-style-type: square;
    // list-style-type: none
    // background: #606060 !important
    // li::before
    //   content: "■ "
    //   color: #606060
    //   font-size: 0.5rem
 }    //   line-height: 1.5rem

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 2rem;
    // color: #145581
    // color: gradient(-45deg, #4e4376 0%, #145581 100%)
    // background: -webkit-linear-gradient(135deg,#145581,#2b5876)
    background: -webkit-linear-gradient(135deg, #4E4376, #145581);
    // background: -webkit-linear-gradient(red, yellow, green)
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1.1rem;
    margin-top: 1.8rem;
    &::before {
      content: '['; } }


  h3 {
    font-size: 1.4rem;
    font-weight: 500;
    color: #145581;
    margin-bottom: 10px;
    line-height: 1.8rem;
    &.grau {
      font-size: 1.18rem;
      font-weight: 500;
      color: #606060;
      margin-bottom: 8px; } }
  ol, ul {
    li {
      margin-bottom: 5px; } }

  .gtplcontent {
    margin-bottom: 12rem; } }

.footer-container {
  background-color: #e0e0e3;
  footer {
    padding: rem(45px) 0 rem(45px) 0;
    // padding-top: 45px
    // padding-top: 40px
    background-color: transparent; } }

.smaller {
  font-size: 0.96rem;
  font-weight: 300; }

.center {
  text-align: center; }

#accordion {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  top: -3rem;
  position: relative;
  h2 {
    // font-weight: $beratung-h2-font-weight
    .btn-link {
      color: $beratung-h2-color;
      font-size: $beratung-h2-font-size-xs;
      font-weight: $beratung-h2-font-weight;
      line-height: $beratung-h2-line-height-xs;
      word-wrap: break-word;
      @media #{$screen-min-xl} {
        font-size: $beratung-h2-font-size-xl; } }
    button {
      white-space: normal;
      text-align: left;
      word-wrap: break-word;
      padding-left: 0;
      padding-right: 0;
      width: 110%;
      &:before {
        content: "\f077";
        //  fa-chevron-up
        font-family: 'FontAwesome';
        position: absolute;
        top: 20px;
        left: -22px;
        // @media #{$screen-min-s}
        @media #{$screen-min-md} {
          left: -35px; }
        // @media #{$screen-min-lg}
 }        // @media #{$screen-min-xl}
      &.collapsed:before {
        content: "\f078";
        //  fa-chevron-down
        font-family: 'FontAwesome';
        position: absolute;
        top: 20px;
        left: -22px;
        // @media #{$screen-min-s}
        @media #{$screen-min-md} {
          left: -35px; }
        // @media #{$screen-min-lg}
 } } }        // @media #{$screen-min-xl}
  h3 {
    font-size: 1.3em; }
  .card {
    border: 0 none;
    border-radius: 0;
    border-bottom: none;
    background-color: transparent;
    @media #{$screen-min-md} {
      background-color: #fff;
      border-bottom: 1px solid $base-blue-dk; } }
  .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: transparent;
    // border-top: 1px solid rgba(0,0,0,0.125)
    // border-top: 1px solid $base-blue-dk
 }    // border-bottom: 1px solid $base-blue-dk
  p {
    font-size: $beratung-text-font-size-xs;
    line-height: $beratung-text-line-height-xs;
    @media #{$screen-min-md} {
      font-size: $beratung-text-font-size-md;
      line-height: $beratung-text-line-height-md; }
    @media #{$screen-min-xxl} {
      font-size: $beratung-text-font-size-xxl;
      line-height: $beratung-text-line-height-xxl; } }
  .card-body {
    padding-right: 0;
    // @media #{$screen-min-s}
    // @media #{$screen-min-md}
    @media #{$screen-min-lg} {
      padding-right: 1.25rem; }
 } }    // @media #{$screen-min-xl}


#headingOne {
  border-top: none;
  border-radius: 0;
  @media #{$screen-min-md} {
    border-top: 1px solid #0B2D42; } }

#accordion .panel-title a:after,
#accordion .panel-title a.collapsed:after {
 content: "\f106";
 font-family: 'FontAwesome';
 color: #de4d4e;
 font-size: 20px;
 line-height: 20px;
 position: absolute;
 top: 14px;
 right: 25px; }

#accordion .panel-title a.collapsed:after {
 content: "\f107";
 color:#808080 {} }

#accordion h2 .btn-link {
    color: $beratung-h2-color; }

.navbox {
  width: 60px;
  // width: 4.05%
  min-height: 0;
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
  top: 0px;
  bottom: auto;
  right: 0px;
  left: auto;
  z-index: 1000;
  cursor: pointer;
  text-align: center;
  @media (min-width: 992px) {
    width: 4.05%; } }

.navbox2 {
  width: 4.05%;
  min-height: 0;
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
  top: 0px;
  bottom: auto;
  right: 0px;
  left: auto;
  z-index: 1000;
  cursor: pointer;
  text-align: center; }

@media screen and (min-width: 992px) {
  .navbox, .navbox2 {
 } }    // background-color: $base-blue-dk

a.hamburger {
    color: $broken-white;
    font-size: 36px;
    position: relative;
    // top: 3.9vh
    top: 14px;
    cursor:pointer {}
    &:hover, &:focus {
        color: $broken-white;
        text-decoration: none; } }
@include media-breakpoint-up(lg) {
  color: #818181; }

.nav-margin {
  margin-right: 0;
  @media (min-width: 576px) {
    margin-right: 4rem; } }

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  //left: 0;
  right: 0;
  background-color: $base-blue-dk;
  overflow-x: hidden;
  cursor: pointer;
  -webkit-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;
  text-align: center; }

.overlay.open {
    width: 100%; }

.overlay-content {
  position: relative;
  top: 15%;
  width: 100%;
  text-align: center;
  margin-top: 30px; }

.overlay {
  a {
    padding: 8px;
    text-decoration: none;
    font-size: 1.4rem;
    color: #818181;
    display: block;
    &:hover, &:focus {
      color: #f1f1f1; } }
  .closebtn {
    position: absolute;
    top: 0;
    right: 45px;
    font-size: 60px; } }

@media screen and (max-height: 450px) {
  .overlay {
    a {
      font-size: 20px; }
    .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px; } } }
@include media-breakpoint-up(md) {
  .overlay.open {} }
@include media-breakpoint-up(lg) {
  .overlay.open {
    width: 40%;
    a {
      font-size: 1.7rem; }
    .closebtn {
      font-size: 5rem; } } }
@include media-breakpoint-up(xl) {
  .overlay.open {
    a {
      font-size: 2.1rem; }
    .closebtn {}
    font-size: 6rem; } }


//ersetzt durch grafik h1.aktuelles
.pagetitle {
  background-image: url("img/h1_bg.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 587px;
  height: 178px;
  color: #fff;
  position: relative;
  top: 125px;
  h1 {
    font-size: 4.5em;
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0; }
  span {
    font-size: 1.2em;
    font-weight: 100 !important; } }

//#banner .imgHeader .datenschutz

// #banner

// hero-datenschutz_m.jpg
// hero-datenschutz_t.jpg
// hero-datenschutz_d.jpg
// hero-datenschutz_xl.jpg


// .bannerimgHeader::before,
// .bannerimgHeader::after
//   clear: both
//   content: ''
//   display: block

.bannerimgHeader {
  background-image: url("img/header-aktuelles.png");
  background-repeat: no-repeat;
  background-position: top left;
  // background-size: cover, contain
  background-size: cover;
  // clear: both
  // overflow-x
  // height: 75.5vw
  height: rem(200px);
  width: 100%;
  // height: 50rem /* 800/16 */
  @media #{$screen-min-s} {
 }    // height: 77vw
  @media #{$screen-min-md} {
    // height: 68.75rem /* 1100/16 */
    // height: 56vw
    height: rem(300px); }
  // @media #{$screen-min-lg}
  //// height: 58.063rem /* 929/16 */
  //// height: 23vw
  //// height: 31vw
  //// height: 25vw
  // @media #{$screen-min-xl}
  //// height: 77.5rem /* 1240/16 */
  //// height: 31vw
  &.datenschutz {
    background-image: url("../img/banner/hero-datenschutz_m.jpg");
    // @media #{$screen-min-s}
    @media #{$screen-min-md} {
      background-image: url("../img/banner/hero-datenschutz_t.jpg"); }
    @media #{$screen-min-lg} {
      background-image: url("../img/banner/hero-datenschutz_xl.jpg"); }
    // @media #{$screen-min-xl}
 }    //// background-image: url("../img/banner/hero-steuer-xl.jpg")
  &.steuerberatung {
    // background-image: url("img/dsberatung.png")
    background-image: url("../img/banner/hero-steuer-m.jpg");
    // @media #{$screen-min-s}
    @media #{$screen-min-md} {
      background-image: url("../img/banner/hero-steuer-t.jpg"); }
    @media #{$screen-min-lg} {
      // background-image: url("../img/banner/hero-steuer-d.jpg")
      // height: 23vw
      background-image: url("../img/banner/hero-steuer-xl.jpg"); }
    // @media #{$screen-min-xl}
 }    //// background-image: url("../img/banner/hero-steuer-xl.jpg")
  &.unternehmensberatung {
    // background-image: url("img/dsberatung.png")
    background-image: url("../img/banner/hero_unternehmensb_m.jpg");
    // @media #{$screen-min-s}
    @media #{$screen-min-md} {
      background-image: url("../img/banner/hero_unternehmensb_t.jpg"); }
    @media #{$screen-min-lg} {
      // background-image: url("../img/banner/hero-steuer-d.jpg")
      // height: 23vw
      background-image: url("../img/banner/hero_unternehmensb_xl.jpg"); }
    // @media #{$screen-min-xl}
 }    //// background-image: url("../img/banner/hero-steuer-xl.jpg")
  &.gradient {
    background: -moz-linear-gradient(78.57% 250% 135deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
    background: -webkit-gradient(linear, 78.57% 250%, 23.58% -134.97%, color-stop(0, rgba(78, 67, 118, 1)), color-stop(1, rgba(20, 85, 129, 1)));
    background: -o-linear-gradient(135deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
    background: -ms-linear-gradient(135deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#4E4376', endColorstr='#145581' ,GradientType=0)";
    background: linear-gradient(-45deg, rgba(78, 67, 118, 1) 0%, rgba(20, 85, 129, 1) 100%);
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4E4376',endColorstr='#145581' , GradientType=1); } }



// #hero banner
//  hero_image_desktop.png 4000 x 1240 px, 100:31
//  hero_image_tablet.png 1960 x 1100 px, 98:55
//  hero_image_mobile.png 1040 x 800 px, 13:10
//
// vor 1.4
.bannertitelbox {
  // position: relative
  // top: 7.5rem
  display: inline-block;
  // @media #{$screen-min-s}
  //// top: 9rem
  // @media #{$screen-min-md}
  //// top: 11rem
  // @media #{$screen-min-lg}
  // @media #{$screen-min-xl}
  h1 {
    // height: 400px
    // width: 68px
    margin-top: 18vh;
    // height: 50rem /* 800/16 */
    // overflow: hidden

    // @media #{$screen-min-s}
    //// height: 77vw
    //margin-top: 20vh
    @media #{$screen-min-md} {
      margin-top: 18vh;
      margin-left: 14vh; }
    @media #{$screen-min-lg} {
      margin-top: 12vh; }
    @media #{$screen-min-xl} {
      // height: 77.5rem /* 1240/16 */
      margin-top: 16vh;
      margin-left: 38vh; }
    span {
 }      // float: right
    @media #{$screen-min-xxxl} {
      margin-top: 19vh;
      margin-left: 40vh; } } }

.header {
  width: 100%; }

.bereichIcon {
    width: 59.5px;
    height: 78.5px;
    object-fit: contain;
    mix-blend-mode: undefined; }

// banner 1.3
h1.beratung-ds {
  background-image: url("../img/icons/hero-datenschutz_xl.svg");
  background-repeat: no-repeat;
  background-position: 0 0; }

h1.beratung-steuer {
  // background-image: url("../img/banner/hero-h1-steuer-xl.svg")
  // background-image: url("../img/banner/h1-steuer-xl.svg")
  background-image: url("../img/banner/steuer-h1_titel-x.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 20vh;
  // width: 85vw
  // @media #{$screen-min-s}
  @media #{$screen-min-md} {
    height: 9vh; }
  // @media #{$screen-min-lg}
  //// height: 12vw
  //// width: 40vw
  @media #{$screen-min-xl} {
    // width: 45vw
    height: 15vh; }
  @media #{$screen-min-xxxl} {
    // width: 45vw
    height: 13vh; } }

.titel,
#svgContainer {
  display: inline-block; }

.titel {
  margin: 0.5em 0 0 1em; }

// banner 1.4

.grafHeader {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  @media #{$screen-max-phone} {
    @media #{$screen-5-3-min} {
      margin-top: 30vh; } }
  @media #{$screen-min-s} {
    @media #{$screen-16-9-min} {
      margin-top: 40vh; }
    // @media #{$screen-4-3-p-min}
    //   margin-top: 20vh
    // @media #{$screen-3-2-p-min}
    //   margin-top: 40vh
    @media #{$screen-3-2-min} {
      margin-top: 35vh; }
    @media #{$screen-5-3-min} {
      margin-top: 40vh; } }
  @media #{$screen-min-md} {
    @media #{$screen-5-8-max} {
      margin-top: 14.5vh; } }
  @media #{$screen-min-lg} {
    display: flex;
    margin-top: 9vh;
    @media #{$screen-min-h1200} {
      margin-top: 4vh; }
 }    // margin-left: 22vw
  @media #{$screen-min-xl} {
    margin-top: 12vh;
 }    // margin-left: 18vw
  @media #{$screen-min-xxxl} {
    margin-top: 18vh;
 } }    // margin-left: 18vw

.titel-container {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 15vh
  // height: auto
  // width: 20vw
  // @media #{$screen-min-s}
  // @media #{$screen-min-md}
  //// top: -5vh
  @media #{$screen-min-lg} {
    display: inline-flex; }
 }  // @media #{$screen-min-xl}

.symbol-container {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: get-xs-vw(2px) {
    // height: 15vh
 }    // height: auto
  @media #{$screen-min-s} {}
  @media #{$screen-min-md} {
 }    // top: -7vh
  @media #{$screen-min-lg} {
    margin-left: 30px;
    display: inline-flex; }
  @media #{$screen-min-xl} {} }

.b-steuerberatung {
  // background-image: url("../img/banner/steuer-h1_titel.svg")
  background-image: url("../img/banner/steuer-h1_titel.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  // height: 40.1px
  // width: 270px
  width: get-vw(700px);
  height: get-vw(104px);
  display: flex;
  // @media #{$screen-min-s}
  //// height: 52px
  //// width: 350px
  //// width: get-vw(350px)
  //// height: get-vw(52px)
  @media #{$screen-min-md} {
    // width: get-vw(600px)
    // height: get-vw(89.1px)
    width: get-md-vw(460px);
    height: get-md-vw(68.3px); }
  @media #{$screen-min-lg} {
    // height: 12vw
    width: get-vw(404px);
    height: get-vw(60px); }
  @media #{$screen-min-xl} {
    // width: 600px
    // height: 89.1px
    width: get-vw(350px);
    height: get-vw(52px); }
  // @media #{$screen-min-xxl}
  @media #{$screen-min-xxxl} {
    width: get-xxxl-vw(650px);
    height: get-xxxl-vw(96.5px); } }

.b-datenschutzberatung {
  // background-image: url("../img/banner/steuer-h1_titel.svg")
  background-image: url("../img/banner/datenschutz-h1_titel.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  // height: 40.1px
  // width: 270px
  width: get-vw(900px);
  height: get-vw(98.1px);
  display: flex;
  // @media #{$screen-min-s}
  //// height: 52px
  //// width: 350px
  //// width: get-vw(350px)
  //// height: get-vw(52px)
  @media #{$screen-min-md} {
    // width: get-vw(600px)
    // height: get-vw(89.1px)
    width: get-md-vw(650px);
    height: get-md-vw(70.8px); }
  @media #{$screen-min-lg} {
    // height: 12vw
    width: get-vw(550px);
    height: get-vw(59.5px); }
  @media #{$screen-min-xl} {
    // width: 600px
    // height: 89.1px
    width: get-vw(500px);
    height: get-vw(54.5px); }
  // @media #{$screen-min-xxl}
  @media #{$screen-min-xxxl} {
    width: get-xxxl-vw(850px);
    height: get-xxxl-vw(92.6px); } }

.b-unternehmensberatung {
  // background-image: url("../img/banner/steuer-h1_titel.svg")
  background-image: url("../img/banner/unternehmensberatung-h1_titel.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  // height: 40.1px
  // width: 270px
  width: get-vw(900px);
  height: get-vw(87.3px);
  display: flex;
  // @media #{$screen-min-s}
  //// height: 52px
  //// width: 350px
  //// width: get-vw(350px)
  //// height: get-vw(52px)
  @media #{$screen-min-md} {
    // width: get-vw(600px)
    // height: get-vw(89.1px)
    width: get-md-vw(650px);
    height: get-md-vw(70.8px); }
  @media #{$screen-min-lg} {
    // height: 12vw
    width: get-vw(550px);
    height: get-vw(59.5px); }
  @media #{$screen-min-xl} {
    // width: 600px
    // height: 89.1px
    width: get-vw(500px);
    height: get-vw(54.5px); }
  // @media #{$screen-min-xxl}
  @media #{$screen-min-xxxl} {
    width: get-xxxl-vw(850px);
    height: get-xxxl-vw(92.6px); } }

.steuerberatung #b-symbol {
  // height: 90px
  height: get-xs-vw(80px);
  @media #{$screen-min-s} {
    height: get-sm-vw(115px);
 }    // height: 14vh
  @media #{$screen-min-md} {
    height: get-md-vw(130px);
 }    // height: 20vh
  @media #{$screen-min-lg} {
    height: get-lg-vw(130px); }
  @media #{$screen-min-xl} {
    height: get-xl-vw(130px); }
  @media #{$screen-min-xxxl} {
    height: get-xxxl-vw(180px); } }

.datenschutz #b-symbol {
  width: get-sm-vw(65px);
  height: get-xs-vw(79.6px);
  @media #{$screen-min-s} {
    width: get-sm-vw(94px);
    height: get-sm-vw(115.1px); }
  @media #{$screen-min-md} {
    width: get-sm-vw(106px);
    height: get-md-vw(129.8px); }
  @media #{$screen-min-lg} {
    width: get-sm-vw(106px);
    height: get-md-vw(129.8px);
    @media #{$screen-4-3-min} {
      width: get-sm-vw(74px);
      height: get-md-vw(90.6px); } }
  @media #{$screen-min-xl} {
    width: get-sm-vw(106px);
    height: get-md-vw(129.8px); }
  @media #{$screen-min-xxxl} {
    width: get-xxxl-vw(147px);
    height: get-xxxl-vw(180px); } }

.unternehmensberatung #b-symbol {
  // height: 90px
  height: get-xs-vw(80px);
  @media #{$screen-min-s} {
    height: get-sm-vw(115px);
 }    // height: 14vh
  @media #{$screen-min-md} {
    height: get-md-vw(130px); }
  @media #{$screen-min-lg} {
    height: get-lg-vw(130px);
    @media #{$screen-4-3-min} {
      height: get-md-vw(90px); } }
  @media #{$screen-min-xl} {
    height: get-xl-vw(130px); }
  @media #{$screen-min-xxxl} {
    height: get-xxxl-vw(180px); } }



// TEST
.farbe1 {
  background-color: #9a6e9f; }
.farbe2 {
  background-color: #a8bddf; }
.mainView.testcolor, .dummycontent {
  background-color: #ebe4e4; }
.tc2 {
  margin-top: 100px; }

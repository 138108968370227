.hkg-logo {
  width: 1.9rem;
  z-index: 100;
  // @media #{$screen-min-s}
  @media #{$screen-min-md} {
    width: 2.5rem; }
  // @media #{$screen-min-lg}
  @media #{$screen-min-xl} {
    width: 2.7rem; } }


#logoTop {
  position: fixed;
  top: 20px;
  left: 25px;
  z-index: 100; }

.logoTopfixed {
  position: relative;
  top: 20px;
  left: 25px;
  z-index: 100;
  display: inline-block; }

#logoBottom {
  width: 1.8vw;
  position: fixed;
  bottom: 20px;
  right: 1vw;
  z-index: 2000; }

.logoAnimation {
  -webkit-animation-duration: 0.7s;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-duration: 0.7s;
  -moz-animation-delay: 0s;
  -moz-animation-iteration-count: 1;
  animation-duration: 0.7s;
  animation-delay: 0;
  animation-iteration-count: 1; }

// Bereichsymbole
#ubsvg {
  // 499
  width: 120px;
  // 472
  height: 113.5px; }

#ds-div {
  display: flex;
  width: auto;
  position: relative;
  // top: 0
  // bottom: 0
  // left: 0
  // right: 0
  // display: flex
  // align-items: center
 }  // justify-content: center


.beratung-ds #ds-div {
  height: 4.5rem;
  margin-top: 3.5rem;
  @media #{$screen-min-s} {
    margin-top: 7rem;
    height: 7rem; }
  @media #{$screen-min-md} {
    height: 80px;
    // padding-left: 2rem
 }    // margin-top: 3px
  // @media #{$screen-min-lg}
  @media #{$screen-min-xl} {
     height: 140px; } }

.beratung-steuer #ds-div {
  height: 4.5rem;
  margin-top: 0;
  @media #{$screen-min-s} {
    // margin-top: 7rem
 }    // height: 7rem
  @media #{$screen-min-md} {
    height: 12vh;
    margin-top: 8vh;
    margin-left: -13vh; }
  @media #{$screen-min-lg} {
    left: 25vw; }
  @media #{$screen-min-xl} {
    height: 18vh;
    left: 14vw;
    margin-top: -2vh; }
  @media #{$screen-min-xxxl} {
    height: 20vh;
    left: 6vw;
    margin-top: -4vh; } }

#pzwei {
  display: block;
  // background-image: url(../img/icons/hero-datenschutz_xl.svg)
  background-image: url('../img/icons/hero-datenschutz-a_xl.svg');
  // background-image: url(../img/icons/datenschutz-ani.svg)
  background-repeat: no-repeat;
  width: 100vw;
  height: 1240px;
  @media #{$screen-min-s} {
 }    // src: 'assets/img/icons/datenschutz-ani.svg'
  @media #{$screen-min-md} {}
  // @media #{$screen-min-lg}
  @media #{$screen-min-xl} {
    background-image: url('../img/icons/hero-datenschutz-a_xl.svg'); }
  object {
    // width: 100vw
 } }    // height: auto

.innerbox {
    margin: 0 auto;
    display: block;
    background-color: #C4D423;
    height: 1024px;
    width: 1280px;
    //width: 100%
 }    //height: 100%

.gridbg {
    background-image: url('/test/img/XLarge-1110-nb.png');
    /*background-position: right bottom, left top;*/
    background-repeat: no-repeat;
    width: 1110px;
    height: 2000px;
 }    //background-size: cover

.pinned-0 {
    position: fixed;
    top: 0;
    left: 0; }

.colbg {
    height: 60px;
    opacity: 0.6;
    z-index: 10; }

.colbg.ca {
    background-color: #8A0879;
    color: #E5DCDC;
    margin-bottom: 1px; }

.colbg.cb {
    background-color: #C4D423;
    color: #454040; }

.maxh {
    height: 100vh; }

.zehnh {
   height: 10vh; }

.hh-25 {
    height: 25vh; }

.hh-30 {
    height: 30vh; }

.bd-highlight {
  // background-color: rgba(215, 20, 114, 0.6)
 }  // border: 1px solid rgba(86,61,124,.15)

.bd-highlight-2 {
  // background-color: rgba(136, 215, 20, 0.6)
 }  // border: 1px solid rgba(86,61,124,.15)
.blue {
  background-color: #004E7C; }

.dkblue {
  background-color: #0B2D42; }

.co-col {
    background-color: #c4e6eb; }

.lintxt {
  font-family: "BLOKKNeue-Regular", serif;
  line-height: 1.3;
  margin: 0 0 1.5em;
  &.sm {
    font-size: 12px; }
  &.md {
    font-size: 16px; } }

.mt-55p {
  margin-top: 55%; }

// Dateiliste
td.grau {
  color: #ac99c8; }
td.demo {
  color: #24a3b9; }
td.ergebnis {
  font-weight: bold;
  font-size: 1.05em; }
.testheight {
  height: 70vh;
  min-height: 100vh; }
.flex1, .flex2 {
  width: 5vh;
  // height: 5vh
  border: 1px solid #000; }
.flex0, .flex3 {
  border: 1px solid #d61a1a; }
.flex1 {
  background-color: #a31919;
  @media #{$screen-min-s} {}
  @media #{$screen-min-md} {
    display: block;
    width: 100%; }
  @media #{$screen-min-lg} {
    width: 5vh; }
  @media #{$screen-min-xl} {} }
.flex2 {
  background-color: rgb(105, 142, 47);
  @media #{$screen-min-s} {}
  @media #{$screen-min-md} {
    display: block;
    width: 100%; }
  @media #{$screen-min-lg} {
    width: 5vh; }
  @media #{$screen-min-xl} {} }
.flexxxx {
  background-color: rgb(202, 196, 215);
  height: 10vh;
  width: 100%; }
.flex0 {
  @media #{$screen-min-s} {}
  @media #{$screen-min-md} {}
  @media #{$screen-min-lg} {
 }    // content: "LG"
  @media #{$screen-min-xl} {} }
.flex3 {
  @media #{$screen-min-s} {}
  @media #{$screen-min-md} {}
  @media #{$screen-min-lg} {}
  @media #{$screen-min-xl} {} }
.flex-xx {
  background-color: rgb(241, 239, 247); }
.flex-item {
  background-color: rgb(228, 250, 122); }

// @media #{$screen-min-s}
// @media #{$screen-min-md}
// @media #{$screen-min-lg}
// @media #{$screen-min-xl}

.flexbox-1, .flexbox-2 {
  width: 400px;
  height: 100px;
  background-color: #0a742e;
  font-size: 3em;
  color: #fff; }
.flexbox-2 {
  background-color: #3fb667; }

$mq-test-color: #be0c6c;

.testframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px; }

.mq-1, .mq-2 {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $mq-test-color;
  color: $mq-test-color;
  min-width: 34px; }

.mq-1 {
 }  // margin-right: 5px

.testframe.mq-1,
.testframe.mq-2 {
  font-size: 1em; }

.mq-info:after {
  content: "xs";
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{$screen-min-s} {
    content: "sm"; }
  @media #{$screen-min-md} {
    content: "md"; }
  @media #{$screen-min-lg} {
    content: "lg"; }
  @media #{$screen-min-xl} {
    content: "xl"; }
  @media #{$screen-min-xxl} {
    content: "xxl"; }
  @media #{$screen-min-xxxl} {
    content: "xxxl"; } }

.mq-ratio:after {
  content: "def";
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{$screen-iphoneX-p-min} {
    content: "iPhone X portrait"; }
  @media #{$screen-iphone-p-min} {
    content: "iPhone SE+ portrait"; }
  @media #{$screen-16-9-p-min} {
    content: "16:9 portrait"; }
  @media #{$screen-5-3-p-min} {
    content: "5:3 portrait"; }
  @media #{$screen-16-10-p-min} {
    content: "16:10 portrait"; }
  @media #{$screen-3-2-p-min} {
    content: "3:2 portrait"; }
  @media #{$screen-4-3-p-min} {
    content: "4:3 portrait"; }
  @media #{$screen-5-4-min} {
    content: "5:4"; }
  @media #{$screen-4-3-min} {
    content: "4:3"; }
  @media #{$screen-3-2-min} {
    content: "3:2"; }
  @media #{$screen-16-10-min} {
    content: "16:10"; }
  @media #{$screen-5-3-min} {
    content: "5:3"; }
  @media #{$screen-16-9-min} {
    content: "16:9"; }
  // @media #{$screen-5-8-min}
  //   content: "5:8"
  // @media #{$screen-iphone6-min}
  //   content: "iPhone 6"
  @media #{$screen-iphone8-min} {
    content: "iPhone 6-8"; } }

// Devive-Erkennung detectizr.min.js/modernizr.min.js

.mq-device:after {
  content: "def";
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.webkit .mq-device:after {
  content: "webkit"; }
.firefox .mq-device:after {
  content: "firefox"; }
.firefox60 .mq-device:after {
  content: "firefox 60"; }
.edge .mq-device:after {
  content: "edge"; }
.edge17 .mq-device:after {
  content: "edge 17"; }
.edge17 .mq-device:after {
  content: "edge"; }
.ios .mq-device:after {
  content: "ios"; }
.android .mq-device:after {
  content: "android"; }
.ie .mq-device:after {
  content: "ie"; }
.ie8 .mq-device:after {
  content: "ie 8"; }
.ie9 .mq-device:after {
  content: "ie 9"; }
// $screen-4-3-min: "screen and ( min-aspect-ratio: 4/3 )";
// $screen-16-9-min: "screen and ( min-aspect-ratio: 640/360 )"; // 16:9 galaxy note
// $screen-16-10-min: "screen and ( min-aspect-ratio: 16/10 )"; // 16:9 galaxy note
// $screen-3-2-min: "screen and ( min-aspect-ratio: 3/2 )"; //
// $screen-5-3-min: "screen and ( min-aspect-ratio: 5/3 )"; //
// $screen-5-4-min: "screen and ( min-aspect-ratio: 5/4 )"; //
// $screen-5-8-min: "screen and ( min-aspect-ratio: 5/8 )"; //  5:8
// $screen-iphone6-min: "screen and ( min-aspect-ratio: 667/375 )"; //

header {
  position: relative;
  min-height: auto;
  text-align: center;
  color: #001A33;
  width: 100%;
  background-color: #fff;
  .header-content {
    position: relative;
    width: 100%;
    padding: 100px 15px;
    text-align: center;
    z-index: 2;
    .inner {
      max-width: rem(400px);
      h1 {
        margin-top: 0;
        margin-bottom: 0; }
      p {
        margin-bottom: 50px;
        font-size: 16px;
        font-weight: 300;
        color: #222; } } } }

@media (min-width: 34em) {
  .icon-lg {
    font-size: 80px; }
  header {
    min-height: 100%;
    .header-content {
      position: absolute;
      top: 50%;
      padding: 0 50px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      .inner {
        margin-right: auto;
        margin-left: auto;
        max-width: 600px;
        // max-width: rem(900px)
        //get-xs-vw($target)
        h1 {
          font-size: 53px; }
        p {
          margin-right: auto;
          margin-left: auto;
          max-width: 80%;
          font-size: 18px; } } } }

  @media #{$screen-min-md} {
    header {
      .inner {
        max-width: 900px; } } }

  section {
    min-height: 600px;
    padding: 100px 0; } }

.list-group-item, .card-footer {
  background-color: transparent; }

textarea {
  resize: none; }

.call-to-action h2 {
  margin: 0 auto 20px; }

// .landing-btn
//   position: fixed
//   bottom: 5vh

.btn.btn-primary {
  background-color: transparent;
  border: 1px solid #007FFF;
  -webkit-transition: all .35s;
  -moz-transition: all .35s;
  transition: all .35s;
  color: #007FFF;
  &:hover {
    opacity: 0.7; } }

.btn-primary-outline {
  // border-color: #007FFF
  border-color: #fff;
  color: #fff;
  &:hover {
    // background-color: #f47264
    background-image: linear-gradient(135deg,#2b5876,#4e4376);
    color: #fff; } }

.btn-xl {
  // padding: 15px 30px
  font-size: 16px;
  @include media-breakpoint-up(md) {
    padding: 15px 30px;
    font-size: 20px; } }

 ::-moz-selection, ::selection {
  text-shadow: none;
  color: #fff;
  background: #222; }

img {
  &::selection, &::-moz-selection {
    color: #fff;
    background: 0 0; } }

#content {
    background-color: lightyellow;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease; }

#content, .sidebar {
    height: 100vh;
    background-color: $base-blue; }

#row-main {
    overflow-x: hidden; /* necessary to hide collapsed sidebars */; }


#first {
  // background: url('../img/landing-image-full.jpg') no-repeat center center fixed
  // background: url('../img/landing-image-full@0,75x.jpg') no-repeat center center fixed
  // background: url('../img/landing-image-full@0,5x.jpg') no-repeat center center fixed
  // background: url('../img/bg/5_4.jpg')
  background: url('../img/bg/landing_1200x960-xl-d.jpg');
  background-color: $base-blue-dk;
  background-repeat: no-repeat;
  // background-position: top left
  background-position: center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  // @media #{$screen-4-3-min}
  //   background: url('../img/bg/4_3.jpg')
  // @media #{$screen-16-9-min}
 }  //   background: url('../img/bg/16_9.jpg')

#frontlogo {
  position: relative;
  top: 10vh;
  width: 90%;
  height: 90%;
  @media #{$screen-min-s} {
    width: 95%;
    height: 95%; }
  @media #{$screen-min-md} {
    top: 0;
    width: 100%;
    height: 100%; } }

.portrait #frontlogo {
  top: 5vh;
  @media #{$screen-min-md} {
    top: 0; } }

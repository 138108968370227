.icon {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #EFEFEF !important; } }

.bluebox.boxDberatung div#ds-symbol {
  width: rem(106px);
  height: rem(137.4px); }

.bluebox.boxUberatung div#ub-symbol {
  width: rem(155px);
  height: rem(156.3px); }

.bluebox.boxSberatung div#sb-symbol {
  width: rem(165px);
  height: rem(156.1px); }

#one {
  height: 10vh; }

/* The sticky */
.stickyHeader {
  // position: -webkit-sticky
  // position: sticky
  // top: 15px
  height: 80px;
  // background-color: #666
  color: #fff;
  width: 100%;
  z-index: 900;
 }  // animation: fadeOutDown 0.5s linear forwards

.stuck {
  position: fixed;
  top: 0;
  // background-color: $base-blue-dk
  background-image: linear-gradient(135deg,#2b5876,#4e4376);
  animation: fadeInDown 0.5s linear forwards; }

#navBanner {
  // background-color: $base-blue-dk
  animation: 0.5s linear forwards;
  height: 80px; }

#navBanner {
  #logoTop {
    top: 13px;
    left: 15px; }
  .hkg-logo {
    width: 2rem; } }

#footer {
  height: 10vh;
  // background-color: $base-blue-dk
  // background-color: $base-blue
  // background-color: #9cb5a9
  // background-color: #bf5600
  // background-color: #f05f40
  background-image: linear-gradient(135deg,#2b5876,#4e4376);
  width: 100%;
  margin-top: 20vh; }

.d-xl-border {
  display: none;
  // @media #{$screen-min-xl}
  //// @media #{$screen-max-1400}
  ////   display: block
  ////   width: 10vw
  @media #{$screen-min-xxl} {
    display: block;
    width: 10vw; } }

.container {
  @media #{$screen-min-xxl} {
    max-width: 1300px; }
  @media #{$screen-min-xxxl} {
    max-width: 1600px; } }
